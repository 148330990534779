import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import tmdbApi from '../../api/tmdbApi';
import apiConfig from '../../api/apiConfig';

import './detail.scss';
import CastList from './CastList';
import VideoList from './VideoList';

import MovieList from '../../components/movie-list/MovieList';

const Detail = () => {

    const [assisti, setAssisti] = useState('opt__itemS');
    const [quero, setQuero] = useState('opt__itemS');
    const [nao, setNao] = useState('opt__itemS');

    const [curti, setCurti] = useState('opt__itemS');
    const [fraco, setFraco] = useState('opt__itemS');

    const [isAssitido, setIsAssistido] = useState(false);
    const [isCurtido, setIsCurtido] = useState(false);

    const { category, id } = useParams();

    const [item, setItem] = useState(null);

    useEffect(() => {
        const getDetail = async () => {
            const response = await tmdbApi.detail(category, id, { params: {} });
            setItem(response);
            window.scrollTo(0, 0);
        }
        getDetail();
    }, [category, id]);

    return (
        <>
            {
                item && (
                    <>
                        <div className="banner" style={{ backgroundImage: `url(${apiConfig.originalImage(item.backdrop_path || item.poster_path)})` }}></div>
                        <div className="mb-3 movie-content container">
                            <div className="movie-content__poster">
                                <div className="movie-content__poster__img" style={{ backgroundImage: `url(${apiConfig.originalImage(item.poster_path || item.backdrop_path)})` }}></div>
                            </div>
                            <div className="movie-content__info">
                                <h1 className="title">
                                    {item.title || item.name}
                                </h1>
                                <div className="genres">
                                    {
                                        item.genres && item.genres.slice(0, 5).map((genre, i) => (
                                            <span key={i} className="genres__item">{genre.name}</span>
                                        ))
                                    }
                                </div>
                                <p className="overview">{item.overview}</p>

                                <div className="genres">
                                    <p>Já viu {item.title || item.name} ?</p><br></br>

                                    <span className={assisti} onClick={() => {
                                        setAssisti('opt__itemP');
                                        setQuero('opt__itemS');
                                        setNao('opt__itemS');
                                        setIsAssistido(!isAssitido)
                                    }
                                    }>Já Assisti</span>

                                    <span className={quero} onClick={() => {
                                        setAssisti('opt__itemS');
                                        setQuero('opt__itemN');
                                        setNao('opt__itemS');
                                        setIsAssistido(false);
                                    }}>Quero Ver</span>

                                    <span className={nao} onClick={() => {
                                        setAssisti('opt__itemS');
                                        setQuero('opt__itemS');
                                        setNao('opt__itemF');
                                        setIsAssistido(false);
                                    }}>Ainda não vi</span>
                                </div>

                                {isAssitido &&
                                    <div className="genres">
                                        <p>Curtiu ?</p><br></br>
                                        <span className={curti} onClick={() => {
                                            setCurti('opt__itemP');
                                            setFraco('opt__itemS');
                                        }}>Curti</span>

                                        <span className={fraco} onClick={() => {
                                            setCurti('opt__itemS');
                                            setFraco('opt__itemN');
                                        }}>Achei fraco</span>
                                    </div>}


                                <div className="cast">
                                    <div className="section__header">
                                        <h2>Elenco</h2>
                                    </div>
                                    <CastList id={item.id} />
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="section mb-3">
                                <VideoList id={item.id} />
                            </div>
                            <div className="section mb-3">
                                <div className="section__header mb-2">
                                    <h2>Similar</h2>
                                </div>
                                <MovieList category={category} type="similar" id={item.id} />
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default Detail;
