import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Home from '../pages/Home';
import Catalog from '../pages/Catalog';
import Detail from '../pages/detail/Detail';
import SignIn from '../pages/SignIn';
import Perfil from '../pages/Profile';
import Assistidos from '../pages/Assistidos';
import Favoritos from '../pages/Favoritos';

const Rotas = () => {
    return (
        <Routes>
            <Route
                path='/:category/search/:keyword'
                element={<Catalog />}
            />
            <Route
                path='/:category/:id'
                element={<Detail />}
            />
            <Route
                path='/:category'
                element={<Catalog />}
            />
            <Route
                path='/'
                element={<Home />}
            />
            <Route
                path='/signin'
                element={<SignIn />}
            />
            <Route
                path='/perfil'
                element={<Perfil />}
            />
            <Route
                path='/favoritos'
                element={<Favoritos />}
            />
            <Route
                path='/assistido'
                element={<Assistidos />}
            />
        </Routes>
    );
}

export default Rotas;
