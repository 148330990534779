import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import './signin.css'
import { AuthContext } from '../../contexts/auth'
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/tmovie.png'

import { FiXCircle } from 'react-icons/fi'


function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nome, setNome] = useState('');
  const { signUp, signIn, loadingAuth, openSignIn, setOpenSignIn } = useContext(AuthContext);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const history = useNavigate();

  function handledSubmitUp(e) {
    e.preventDefault();

    if (nome !== '' && email !== '' && password !== '') {
      signUp(email, password, nome);
    }
  }

  function handledSubmit(e) {
    e.preventDefault();
    if (email !== '' && password !== '') {
      signIn(email, password);
    }
  }


  return (
    <div className="container-center">
      {openSignUp &&
        <> <FiXCircle size={40} className="btn-close-login" onClick={() => { setOpenSignIn(false) }} />
          <div className="login">
            <div className="logo-area">
              <h1>Criar conta</h1>
            </div>

            <form onSubmit={handledSubmitUp}>
              <input type="text" placeholder="Seu nome" value={nome} onChange={(e) => setNome(e.target.value)} />
              <input type="text" placeholder="email@email.com" value={email} onChange={(e) => setEmail(e.target.value)} />
              <input type="password" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} />
              <button type="submit">{loadingAuth ? 'Preparando tudo...' : 'Cadastrar'}</button>
            </form>
            <p className='register' onClick={() => {
              setOpenSignUp(false);
              setIsOpen(true);
            }}>Já tem uma conta? Entre</p></div></>}

      {isOpen && <>
        <FiXCircle size={40} className="btn-close-login" onClick={() => { setOpenSignIn(false) }} />
        <div className="login">
          <div className="logo-area">
            <h1>Entrar</h1>
          </div>

          <form onSubmit={handledSubmit}>

            <input type="text" placeholder="email@email.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="password" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button type="submit">{loadingAuth ? 'Carregando...' : 'Acessar'}</button>
          </form>

          <div className='info'>
            <p className='register' onClick={() => {
              setOpenSignUp(true);
              setIsOpen(false);
            }}>Cadastre-se</p>
            {/* <Link className='forgot' to="/forgot">Esqueceu a senha?</Link> */}
          </div>
        </div></>}
    </div >
  );
}

export default SignIn;
