import 'swiper/swiper.min.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './contexts/auth'
import { ToastContainer } from 'react-toastify';

import Rotas from './config/Routes';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';



function App() {
    return (

        <BrowserRouter>
            <AuthProvider>
                <ToastContainer autoClose={3000} />
                <Header />
                <Rotas />
                <Footer />
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
