import React, { useRef, useEffect, useContext, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import SignIn from '../../pages/SignIn'
import ReactModal from 'react-modal'

import './header.scss';

import logo from '../../assets/tmovie.png';

import { AuthContext } from '../../contexts/auth'
import { FiSettings, FiLogOut, FiHeart, FiAirplay, FiUser } from 'react-icons/fi'

const headerNav = [
    {
        display: 'Inicio',
        path: '/'
    },
    {
        display: 'Filmes',
        path: '/movie'
    },
    {
        display: 'Series',
        path: '/tv'
    }
];

const Header = () => {

    const { pathname } = useLocation();
    const headerRef = useRef(null);
    const { user, signOut, setOpenSignIn, openSignIn } = useContext(AuthContext);
    const [isListOpen, setIsListOpen] = useState(false);


    const active = headerNav.findIndex(e => e.path === pathname);

    useEffect(() => {
        const shrinkHeader = () => {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                headerRef.current.classList.add('shrink');
            } else {
                headerRef.current.classList.remove('shrink');
            }
        }
        window.addEventListener('scroll', shrinkHeader);
        return () => {
            window.removeEventListener('scroll', shrinkHeader);
        };

    }, []);

    return (
        <div ref={headerRef} className="header">
            <div className="header__wrap container">
                <div className="logo">
                    <img src={logo} alt="" />
                    <Link to="/">Indica Filme</Link>
                </div>
                <ul className="header__nav">
                    {
                        headerNav.map((e, i) => (
                            <li key={i} className={`${i === active ? 'active' : ''}`}>
                                <Link to={e.path}>
                                    {e.display}
                                </Link>
                            </li>
                        ))
                    }
                    {!user &&
                        <li onClick={()=> {setOpenSignIn(!openSignIn)}}>
                            Login
                        </li>}
                    {user &&
                        <div className="dd-wrapper"
                            onMouseEnter={() => { setIsListOpen(!isListOpen) }}
                            onMouseLeave={() => { setIsListOpen(!isListOpen) }}
                            >
                            
                            <div className="dd-header">
                                <li>Meu Perfil</li>
                            </div>
                            {isListOpen && (
                                <div className="dd-list">
                                    <Link to="/perfil" className="dd-list-item"><FiUser className="fi" color="green"/>Cadastro</Link>
                                    <Link to="/assistido" className="dd-list-item"><FiAirplay className="fi" color="aqua"/>Já assisti</Link>
                                    <Link to="/favoritos" className="dd-list-item"><FiHeart className="fi" color="red" />Favoritos</Link>
                                    <button className="dd-list-item" onClick={signOut}><FiLogOut className="fi" color="white" />Sair</button>
                                </div>)}
                        </div>
                    }
                </ul>
            </div>
            {openSignIn && <SignIn />}
        </div>
    );
}

export default Header;
