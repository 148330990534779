// v9 compat packages are API compatible with v8 code
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA9767hIQeUXIl_UFE-_mSSYw7k6R2AQEo",
    authDomain: "indicafilme-35094.firebaseapp.com",
    projectId: "indicafilme-35094",
    storageBucket: "indicafilme-35094.appspot.com",
    messagingSenderId: "913013143151",
    appId: "1:913013143151:web:1921434ced5637fc09bf9a"
};


// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default firebase;